<template>
  <div style="width:100%;height: 100%;background-color: #fff">
    <div class="search-content">
      <a-form layout="inline">
        <a-form-item label="">
          <a-input v-model:value="searchForm.indexName" placeholder="指标名称" />
        </a-form-item>
        <a-form-item label="">
          <a-button :loading="loading" @click="search" type="primary" style="margin-right:15px">
            搜索
          </a-button>
          <a-button :loading="loading" @click="reset">
            重置
          </a-button>
        </a-form-item>
      </a-form>
    </div>
    <a-divider />
    <div class="table-content">
      <a-space class="edit-content" :size="8">
        <div>共<span style="color:#1A78FC">{{listData.length}}</span>个</div>
        <a-button @click="addShow=true" type="primary" class="primary-btn">新增指标</a-button>
        <div>
          <a-button v-show="!selectedRowKeys.length<=0" @click="deletePrompt=true,deleteShow=false" class="delete-btn">删除</a-button>
          <a-button v-show="selectedRowKeys.length<=0" disabled>删除</a-button>
        </div>
      </a-space>
      <a-table :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange}" bordered size="small" :row-key="record => record.id" :columns="columns" :pagination="pagination" :loading="loading" @change="handleTableChange" :data-source="listData" />
    </div>
  </div>
  <!--新增指标-->
  <a-modal v-model:visible="addShow" title="新增指标" @ok="addData" :confirmLoading="confirmLoading" :destroyOnClose="true">
    <a-form ref="formRef" :model="addForm" layout="vertical" :rules="rules">
      <a-form-item label="指标分类" :name="['indexType','value']">
        <a-select v-model:value="addForm.indexType.value" placeholder="请选择分类">
          <a-select-option :value="1">关键绩效指标（KPI）</a-select-option>
          <a-select-option :value="2">关键绩效事件（KPA）</a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item label="指标名称" name="indexName">
        <a-input v-model:value="addForm.indexName" />
      </a-form-item>
      <a-form-item label="指标定义、定义/公式" name="description">
        <a-textarea v-model:value="addForm.description" :rows="4" />
      </a-form-item>
    </a-form>
  </a-modal>
  <!--删除提示框-->
  <a-modal v-model:visible="deletePrompt" title="提示">
    <div style="text-align: center">
      <h2 v-show="!deleteShow" style="color:#D9001B">是否确认删除指标？</h2>
      <h2 v-show="deleteShow" style="color:#1A78FC">删除成功！</h2>
    </div>
    <template #footer>
      <a-space>
        <a-button type="primary" class="primary-btn" @click="deletePrompt=false">取消</a-button>
        <a-button v-show="!deleteShow" @click="deleteData" type="primary">确认</a-button>
        <a-button v-show="deleteShow" @click="deletePrompt=false" type="primary">确认</a-button>
      </a-space>
    </template>
  </a-modal>
</template>

<script>
import { onMounted, reactive, toRefs, ref } from 'vue'
import { page, add, deleteStandardList } from '@/api/achievement/standardList'
import { message } from 'ant-design-vue'
export default {
  setup () {
    const formRef = ref(null)
    const state = reactive({
      loading: false,
      addShow: false,
      deleteShow: false,
      deletePrompt: false,
      confirmLoading: false,
      addForm: {
        indexType: { label: '', value: null },
        name: '',
        definition: ''
      },
      searchForm: {
        indexName: ''
      },
      rules: {
        indexType: { value: { required: true, message: '请选择分类', trigger: 'change', type: 'number' } },
        indexName: { required: true, message: '请输入名称', trigger: 'blur' },
        description: { required: true, message: '请定义规则/公式', trigger: 'blur' }
      },
      mirrorAddForm: {}, // 镜像添加表单对象数据
      mirrorSearchForm: {}, // 镜像搜索表单对象数据
      listData: [],
      selectedRowKeys: [],
      columns: [
        {
          title: '指标分类',
          align: 'center',
          dataIndex: 'indexType.label'
        }, {
          title: '指标名称',
          align: 'center',
          dataIndex: 'indexName'
        }, {
          title: '指标定义、定义/公式',
          align: 'center',
          dataIndex: 'description'
        }, {
          title: '添加人',
          align: 'center',
          dataIndex: 'operator'
        }, {
          title: '添加时间',
          align: 'center',
          dataIndex: 'createTime'
        }
      ],
      pagination: {
        current: 1,
        pageSize: 10,
        total: 0,
        showSizeChanger: true,
        showTotal: (total, range) => `共 ${total} 条`
      }
    })
    const onSelectChange = selectedRowKeys => {
      state.selectedRowKeys = selectedRowKeys
    }
    const loadData = () => {
      state.loading = true
      page({
        ...state.searchForm,
        current: state.pagination.current,
        size: state.pagination.pageSize
      }).then(res => {
        if (res.code === 10000) {
          state.listData = res.data.records
          state.pagination.current = res.data.current
          state.pagination.pageSize = res.data.size
          state.pagination.total = res.data.total
        }
      }).catch(err => { console.log('错误', err) })
        .finally(() => { state.loading = false })
    }
    const handleTableChange = page => {
      state.pagination.current = page.current
      state.pagination.pageSize = page.pageSize
      loadData()
    }
    const search = () => {
      state.pagination.current = 1
      loadData()
    }
    const reset = () => {
      state.searchForm = JSON.parse(state.mirrorSearchForm)
      state.pagination.current = 1
      loadData()
    }
    const deleteData = () => {
      deleteStandardList(state.selectedRowKeys).then(res => {
        if (res.code === 10000) {
          state.deleteShow = true
          state.selectedRowKeys = []
          loadData()
        }
      }).catch(err => { console.log('错误', err) })
    }
    const addData = () => {
      formRef.value.validate()
        .then(() => {
          state.confirmLoading = true
          add({
            ...state.addForm
          }).then(res => {
            if (res.code === 10000) {
              message.success(res.msg)
              state.addShow = false
              state.addForm = JSON.parse(state.mirrorAddForm)
              loadData()
            }
          }).catch(err => { console.log('错误', err) }).finally(() => { state.confirmLoading = false })
        }).catch((error) => {
          console.log(error) // 正则错误提示
        })
    }
    onMounted(() => {
      loadData()
      state.mirrorSearchForm = JSON.stringify(state.searchForm)
      state.mirrorAddForm = JSON.stringify(state.addForm)
    })

    return {
      ...toRefs(state),
      formRef, // ref
      addData,
      search,
      reset,
      deleteData,
      onSelectChange,
      handleTableChange
    }
  }
}
</script>

<style lang="less" scoped>
@import './index.less';
</style>
